.checkout-summary {
  
}
.select-current-shop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.checkout-orientation {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  > div {
    flex: 1;
    flex-shrink: 0;
  }
  h1 {
    font-size: 1.1em;
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-bottom: 0em;
  }
  h5 {
    font-size: 0.5em;
    margin-left: 0.1em;
    margin-right: 0.1em;
  }
  span {
    font-size: 0.4em;
    margin: 0em;
    padding: 0em;
  }
}
.checkout-total-style {
  font-size: 3em;
}
.checkout-total {
  text-align: right;
}
.checkout-pay {
  text-align: right;
}
