.checkout-invoice {
  width: 400px;
}
.checkout-invoice:after {
  display: block;
  text-align: center;
}
@media print {
  .drawer {
    display: none;
  }
  .icon-toolbar {
    display: none;
  }
  .invoice-print-options {
    display: none;
    visibility: hidden;
  }
  .invoice-print-test-options {
    display: none;
    visibility: hidden;
  }
  .h-session-container {
    display: none;
  }
  // @page {
  //   //size: A7;
  //   //size: auto;
  //   size: portrait;
  //   size: 6in auto;
  // }
}
