.search-result-item {
  h5 {
    margin: 0px;
    font-weight: bold;
    font-size: 22px;
  }
  h6 {
    font-size: 18px;
    margin: 0px;
  }
}
.visitor-serach-modal {
  .ui.search>.results {
    width: 550px;
  }
}
.customer-serach-modal {
  .ui.search>.results {
    width: 550px;
  }
}
.visitor-search-form {
  text-align: right;
}
