.guests-main-card-list {
  .guests-main-list-item {
    cursor: pointer;
    width: 300px;
    height: 150px;
    background: linear-gradient(0deg, rgb(34, 124, 106) 0%, rgb(134, 159, 146) 100%);
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    .content {
      padding-left: 8px;
      padding-top: 8px;
      padding-right: 8px;
      position: absolute;
      width: 100%;
      height: 100%;
      .name {
        font-size: 35px;
        line-height: 40px;
        padding-right: 10px;
      }
      .attribute {
        margin-top: 5px;
        overflow-y: auto;
        font-size: 1.1em;
        height: 70px;
      }
    }
    .header {
      position: absolute;
      padding-top: 8px;
      padding-right: 8px;
      right: 0;
      font-weight: bold;

      background-color: #ff006d;
      color: #ffffff;
      padding: 4px 15px;
      border-bottom-left-radius: 10px;
    }
    .footer {
      position: absolute;
      bottom: 0;
      background-color: rgba(31, 32, 31, 0.255);
      width: 100%;
      .availability {
        font-size: 20px;
        padding-left: 5px;
        color: #fff4f473;
      }
    }
    .booking {
      position: absolute;
      bottom: 0;
      right: 0;
      display: inline-block;
      h1 {
        font-size: 50px;
        line-height: 40px;
        color: #fff4f473;
      }
    }
    .sale {
      position: absolute;
      top: 0;
      left: 20px;
      width: 45px;
      height: 50px;
      background: #a79b41;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    .housekeeping {
      position: absolute;
      top: 0;
      left: 40px;
      width: 45px;
      height: 50px;
      background: #3eb0b4;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    .maintenance {
      position: absolute;
      top: 0;
      left: 60px;
      width: 45px;
      height: 50px;
      background: #d41717;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  .guests-main-list-item.room-free {
    // background: linear-gradient(0deg, rgb(27, 107, 91) 0%, rgb(49, 155, 100) 100%); 
    // background: linear-gradient(0deg, rgb(25, 118, 99) 0%, rgb(25, 118, 99) 100%);
    // background: linear-gradient(0deg, #158086 0%, #1b868c 100%);
    // background: linear-gradient(0deg, rgb(65, 184, 160) 0%, rgb(35, 107, 93) 100%);
    // background: linear-gradient(0deg, rgb(22, 116, 122) 0%, rgb(24, 113, 118) 100%);
    // background: linear-gradient(0deg, rgb(104 104 104) 0%, rgb(92 92 92) 100%);
    // background: linear-gradient(0deg, rgb(104, 104, 104) 0%, rgb(92, 92, 92) 100%);
    background: linear-gradient(0deg, rgb(4, 63, 66) 0%, rgb(13, 71, 74) 100%);
  }
  .guests-main-list-item.room-busy {
    // background: linear-gradient(0deg, rgb(131, 0, 0) 0%, rgb(162, 47, 47) 100%);
    // background: linear-gradient(0deg, rgb(217, 50, 50) 0%, rgb(162, 31, 31) 100%);
    background: linear-gradient(0deg, rgb(93, 32, 32) 0%, rgb(102, 25, 25) 100%);
  }
}
