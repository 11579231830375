.controls-actions {
  height: 350px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-size: 20px;
  .pay-actions {
    flex: 3;
    flex-shrink: 0;
  }
  .numbers-actions-vertical {
    flex: 4;
    flex-shrink: 0;
  }
}
.pay-actions {
  display: flex;
  flex-direction: column;
  .action-customer {
    flex: 2;
  }
  .action-payment {
    flex: 6;
  }
}
.numbers-actions-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
  .actions {
    flex: 1;
  }
}
.numbers-actions-horizontal {
  display: flex;
  flex-direction: row;
  height: 100%;
  .actions {
    flex: 1;
  }
}
