.app-bg {
  background: url('../../assets/images/pexels-bruno-maceiras-2467558.jpg')  no-repeat center center fixed;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  .grix {
    background-color: #292727d4;
    width: 100%;
    height: 100%;
  }
}
.home-title {
  h1 {
    text-align: center;
    font-size: 80px;
    font-weight: bold;
  }
  p {
    text-align: center;
    font-size: 1.3em;
  }
}
