.report-history-list {
  font-size: 1.2em;
  margin-bottom: 10px;

  .report-list-item {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 5px;
    .center {
      text-align: center;
    }
    .right {
      text-align: right;
    }
  }
  .report-list-item:hover {
    background-color: rgba(255, 255, 255, 0.137); 
  }
}
