.guests-main-room {
  text-align: center;
}
.guests-main-rooms-list {
  .customer-information {
    font-size: 1.5em;
    line-height: 1em;
  }
}

@media only print, screen and (max-width: 768px) {
  .guests-main-container {
    width: 100%;
    height: 100%;
  }
}
@media only print, screen and (min-width: 769px) and (max-width: 1008px) {
  .guests-main-container {
    width: 700px;
    min-width: 450px;
    height: 100%;
    margin: auto;
  }
}
@media only print, screen and (min-width: 1008px) {
  .guests-main-container {
    width: 900px;
    min-width: 450px;
    height: 100%;
    margin: auto;
  }
}
