.checkout-letter-cart {
  .h-cart-summary.cart-header {
    font-weight: bold;
  }
  .cart-summary {
    display: flex;
    flex-direction: row;
    height: 20px;
    line-height: 1;
    // border-bottom: 1px solid #dadada;
    > div {
      padding: 2px 5px;
    }
    .description {
      flex: 14;
      border-left: 1px solid;
      border-right: 1px solid;
    }
    .amount {
      border-left: 1px solid;
      text-align: left;
      flex: 4;
    }
    .price {
      text-align: left;
      flex: 5;
      border-right: 1px solid;
    }
    .total {
      border-right: 1px solid;
      text-align: right;
      flex: 5;
    }
  }
  .h-cart-summary {
    display: flex;
    flex-direction: row;
    border-top: 1px solid;
    border-bottom: 1px solid;
    > div {
      padding: 2px 5px;
    }
    .h-description {
      flex: 14;
      border-left: 1px solid;
      border-right: 1px solid;
    }
    .h-amount {
      border-left: 1px solid;
      text-align: left;
      flex: 4;
    }
    .h-price {
      text-align: left;
      flex: 5;
      border-right: 1px solid;
    }
    .h-total {
      border-right: 1px solid;
      text-align: right;
      flex: 5;
    }
  }
}
