// .h-header {
//   .h-title {
//     position: absolute;
//     bottom: 20px;
//     left: 20px;
//     color: white;
//     font-size: 20px;
//     p {
//       margin: 0;
//     }
//   }
// }
// .h-types {
//   width: 600px;
//   max-width: 800px;
//   min-width: 400px;
//   margin: auto;
//   overflow-x: auto;
//   overflow-y: hidden;
// }

.test2 {
body, html {
	font-family: 'Raleway', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 300;
	width: 100% !important;
	height: 100% !important;
}
h2 {
	margin: 0 0 20px 0;
	font-weight: 500;
	font-size: 34px;
	color: #333;
	text-transform: uppercase;
}
h3 {
	font-size: 22px;
	font-weight: 500;
	color: #333;
}
h4 {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: 400;
	color: #333;
}
h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}
p {
	font-size: 16px;
}
p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}
a {
	color: #8eb640;
}
a:hover, a:focus {
	text-decoration: none;
	color: #222;
}
ul, ol {
	list-style: none;
}
.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.clearfix {
	display: inline-block;
}
* html .clearfix {
	height: 1%;
}
.clearfix {
	display: block;
}
ul, ol {
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}
hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #8eb640;
	margin: 0;
	margin-bottom: 40px;
	border: 0;
}
.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
	outline-offset: none;
}
/* Navigation */
#menu {
	padding: 20px;
	transition: all 0.8s;
}
#menu.navbar-default {
	background-color: rgba(248, 248, 248, 0);
	border-color: rgba(231, 231, 231, 0);
}
#menu a.navbar-brand {
	font-family: 'Dancing Script', cursive;
	font-size: 36px;
	color: #8eb640;
	font-weight: 700;
	letter-spacing: 1px;
}
#menu.navbar-default .navbar-nav > li > a {
	text-transform: uppercase;
	color: #ddd;
	font-weight: 500;
	font-size: 15px;
	padding: 5px 0;
	border: 2px solid transparent;
	letter-spacing: 0.5px;
	margin: 10px 15px 0 15px;
}
#menu.navbar-default .navbar-nav > li > a:hover {
	color: #8eb640;
}
.on {
	background-color: #262626 !important;
	padding: 0 !important;
	padding: 10px 0 !important;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	color: #8eb640 !important;
	background-color: transparent;
}
.navbar-toggle {
	border-radius: 0;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #8eb640;
	border-color: #8eb640;
}
.navbar-default .navbar-toggle:hover>.icon-bar {
	background-color: #FFF;
}
.section-title {
	margin-bottom: 70px;
}
.section-title .overlay {
	padding: 80px 0;
	background: rgba(0, 0, 0, 0.7);
}
.section-title p {
	font-size: 22px;
	color: rgba(255,255,255,0.8);
}
.section-title hr {
	margin: 0 auto;
	margin-bottom: 40px;
}
.btn-custom {
	text-transform: uppercase;
	color: #fff;
	background-color: #72a411;
	border: 0;
	padding: 14px 20px;
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.5px;
	border-radius: 0;
	margin-top: 20px;
	transition: all 0.5s;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #fff;
	background-color: #628d0f;
}
/* Header Section */
.intro {
	display: table;
	width: 100%;
	padding: 0;
	background: url(./img/intro-bg.jpg) no-repeat center center;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro .overlay {
	background: rgba(0,0,0,0.4);
}
.intro h1 {
	font-family: 'Dancing Script', cursive;
	color: #fff;
	font-size: 10em;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 10px;
}
.intro span {
	color: #a7c44c;
	font-weight: 600;
}
.intro p {
	color: #fff;
	font-size: 32px;
	font-weight: 300;
	margin-top: 10px;
	margin-bottom: 40px;
}
header .intro-text {
	padding-top: 250px;
	padding-bottom: 200px;
	text-align: center;
}
/* About Section */
#about {
	padding: 120px 0;
}
#about h3 {
	font-size: 20px;
}
#about .about-text {
	margin-left: 10px;
}
#about .about-img {
	display: inline-block;
	position: relative;
}
#about .about-img:before {
	display: block;
	content: '';
	position: absolute;
	top: 8px;
	right: 8px;
	bottom: 8px;
	left: 8px;
	border: 1px solid rgba(255, 255, 255, 0.5);
}
#about p {
	line-height: 24px;
	margin: 15px 0 30px;
}
/* Menu Section */
#restaurant-menu {
	padding: 0 0 60px 0;
}
#restaurant-menu .section-title {
	background: #444 url(./img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover;
}
#restaurant-menu .section-title h2 {
	color: #fff;
}
#restaurant-menu img {
	width: 300px;
	box-shadow: 15px 0 #a7c44c;
}
#restaurant-menu h3 {
	padding: 10px 0;
	text-transform: uppercase;
}
#restaurant-menu .menu-section hr {
	margin: 0 auto;
}
#restaurant-menu .menu-section {
	margin: 0 20px 80px;
}
#restaurant-menu .menu-section-title {
	font-size: 26px;
	display: block;
	font-weight: 500;
	color: #444;
	margin: 20px 0;
	text-align: center;
}
#restaurant-menu .menu-item {
	margin: 35px 0;
	font-size: 18px;
}
#restaurant-menu .menu-item-name {
	font-weight: 600;
	font-size: 17px;
	color: #555;
	border-bottom: 2px dotted rgb(213, 213, 213);
}
#restaurant-menu .menu-item-description {
	font-style: italic;
	font-size: 15px;
}
#restaurant-menu .menu-item-price {
	float: right;
	font-weight: 600;
	color: #555;
	margin-top: -26px;
}
/* Portfolio Section */
#portfolio {
	padding: 0 0 120px 0;
}
#portfolio .section-title {
	background: #444 url(./img/gallery-bg.jpg) center center no-repeat fixed;
	background-size: cover;
	margin-bottom: 50px;
}
#portfolio .section-title h2 {
	color: #fff;
}
.categories {
	padding-bottom: 30px;
	text-align: center;
}
ul.cat li {
	display: inline-block;
}
ol.type li {
	display: inline-block;
	margin: 0 10px;
	padding: 20px 0;
}
ol.type li a {
	color: #999;
	font-weight: 500;
	font-size: 14px;
	padding: 12px 24px;
	background: #eee;
	border: 0;
	border-radius: 0;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
ol.type li a.active {
	color: #fff;
	background-color: #8eb640;
}
ol.type li a:hover {
	color: #fff;
	background-color: #8eb640;
}
.isotope-item {
	z-index: 2
}
.isotope-hidden.isotope-item {
	z-index: 1
}
.isotope, .isotope .isotope-item {
	/* change duration value to whatever you like */
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	transition-duration: 0.8s;
}
.isotope-item {
	margin-right: -1px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.isotope {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	transition-property: height, width;
}
.isotope .isotope-item {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	transition-property: transform, opacity;
}
.portfolio-item {
	margin: 15px 0;
}
.portfolio-item .hover-bg {
	overflow: hidden;
	position: relative;
}
.portfolio-item .hover-bg:before {
	display: block;
	content: '';
	position: absolute;
	top: 6px;
	right: 6px;
	bottom: 6px;
	left: 6px;
	border: 1px solid rgba(255, 255, 255, 0.6);
}
.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: rgba(0, 0, 0, 0.6);
	padding: 30% 0 0 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
}
.hover-bg .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 17px;
	letter-spacing: 0.5px;
	font-weight: 500;
}
.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.hover-bg:hover .hover-text {
	opacity: 1;
}
/* Team Section */
#team {
	color: #fff;
	background: #444 url(./img/team-bg.jpg) center top no-repeat fixed;
	background-size: cover;
}
#team .overlay {
	padding: 120px 0 80px 0;
	background: rgba(0, 0, 0, 0.6);
}
#team h2, #team p {
	color: #fff;
}
#team hr {
	background: #fff;
}
#team h3 {
	color: #fff;
	font-weight: 400;
	font-size: 20px;
	margin: 5px 0;
}
#team img {
	width: 280px;
}
#team .thumbnail {
	background: transparent;
	border: 0;
}
#team .thumbnail .team-img {
	display: inline-block;
	position: relative;
}
#team .thumbnail .team-img:before {
	display: block;
	content: '';
	position: absolute;
	top: 8px;
	right: 8px;
	bottom: 8px;
	left: 8px;
	border: 1px solid rgba(255, 255, 255, 0.2);
}
#team .thumbnail .caption {
	padding-top: 10px;
}
#team .thumbnail .caption p {
	color: rgba(255,255,255,0.7);
	padding: 0 10px;
	font-size: 15px;
}
/* Call Reservation Section */
#call-reservation {
	padding: 90px 0;
	color: #fff;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8eb640+50,779936+100 */
	background: rgb(142,182,64); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(142,182,64,1) 50%, rgba(119,153,54,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(142,182,64,1) 50%, rgba(119,153,54,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(142,182,64,1) 50%, rgba(119,153,54,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8eb640', endColorstr='#779936', GradientType=0 ); /* IE6-9 */
}
#call-reservation .overlay {
	padding: 80px 0;
	background: #8eb640;
}
#call-reservation h2 {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 400;
	margin: 0;
}
#call-reservation hr {
	background: #fff;
}
#call-reservation h3 {
	color: #fff;
	font-weight: 500;
	font-size: 20px;
	margin: 5px 0;
}
/* Contact Section */
#contact {
	padding: 100px 0 60px 0;
	background: #F6F6F6;
}
#contact .section-title p {
	color: #777;
}
#contact form {
	padding: 0;
}
#contact h3 {
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 400;
	color: #555;
}
#contact .text-danger {
	color: #cc0033;
	text-align: left;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
#contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
#contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}
#contact .contact-item {
	margin: 20px 0 40px 0;
}
#contact .contact-item span {
	font-weight: 400;
	color: #aaa;
	text-transform: uppercase;
	margin-bottom: 6px;
	display: inline-block;
}
#contact .contact-item p {
	font-size: 16px;
}
/* Footer Section*/
#footer {
	background: #262626;
	padding: 50px 0 0 0;
}
#footer h3 {
	color: #8eb640;
	font-weight: 400;
	font-size: 18px;
	text-transform: uppercase;
	margin-bottom: 20px;
}
#footer .copyrights {
	padding: 20px 0;
	margin-top: 50px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#779936+0,8eb640+50 */
	background: rgb(119,153,54); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(119,153,54,1) 0%, rgba(142,182,64,1) 50%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(119,153,54,1) 0%, rgba(142,182,64,1) 50%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(119,153,54,1) 0%, rgba(142,182,64,1) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#779936', endColorstr='#8eb640', GradientType=0 ); /* IE6-9 */
}
#footer .social {
	margin: 20px 0 30px 0;
}
#footer .social ul li {
	display: inline-block;
	margin: 0 20px;
}
#footer .social i.fa {
	font-size: 26px;
	padding: 4px;
	color: #fff;
	transition: all 0.3s;
}
#footer .social i.fa:hover {
	color: #eee;
}
#footer p {
	font-size: 15px;
	color: rgba(255,255,255,0.8)
}
#footer a {
	color: #f6f6f6;
}
#footer a:hover {
	color: #333;
}
}
