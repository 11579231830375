.main-product-list {
  width: 100%;
  display: grid;
  font-size: 18px;
  .linear-list {
    > div {
      float: left;
      padding-top: 12px;
    }
    .name {
      width: 30%;
    }
    .field-title {
      width: 40%;
    }
    .field {
      font-size: 15px;
      width: 40%;
      padding-top: 0px;
    }
    .data {
      width: 10%;
    }
  }
}
