.checkout-invoice-cart {
  .cart-summary.cart-header {
    font-size: 12px;
  }
  .cart-summary {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    .description {
      flex: 14;
    }
    .amount {
      text-align: left;
      flex: 3;
    }
    .price {
      text-align: left;
      flex: 5;
    }
    .total {
      text-align: right;
      flex: 5;
    }
  }
}
