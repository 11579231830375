.checkout-list {
  margin: 20px;
  .checkout-list-item {
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 1.5em;
    padding: 1em;
    display: flex;
    flex-direction: row;
    .index-counter {
      width: 100px;
      text-align: right;
      flex-shrink: 0;
    }
    .text {
      flex-shrink: 0;
    }
    .title {
      font-size: 1.3em;
      flex-grow: 1;
      padding-left: 20px;
      padding-right: 20px;
    }
    .sub-title {
      flex-grow: 1;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .checkout-list-item:hover, .checkout-list-item-select {
    background-color: rgba(255, 255, 255, 0.137); 
  }

}
